import React from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import { Helmet } from 'react-helmet-async'


function AppLayout() {
    const path = window.location.pathname; 
    const firstSegment = path.split('/')[1];
    return (
        <>
            <Helmet>
                <title>Best social casino games in {firstSegment=='nz'? 'New Zealand':firstSegment=='ch'? 'Switzerland':'Finland'}</title>
                <meta name="description" content="Best social casino games in Finland" />
            </Helmet>
            <Header />
            <Outlet />
            <Footer />
        </>
    )
}

export default AppLayout
